import React from 'react';
import { Router } from '@reach/router';
import { view } from 'react-easy-state';

import store from '../stores';
import Layout from '../components/layout';

class IndexRoute extends React.Component {
  componentDidMount() {
    store.keyword = '';
    store.countryCode = 'my';
    store.artworks = null;
  }

  render() {
    return null;
  }
}

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Router>
          <IndexRoute path="/" />
        </Router>
      </Layout>
    );
  }
}

export default view(IndexPage);
